html {
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
}

* {
    scroll-margin-top: 64px;
}

@media (max-width: 870px) {
    * {
        scroll-margin-top: 44px;
    }
}