#contact-me-section {
    min-height: 100vh;
    margin: 0 20px;
    overflow-x: visible;
}

.contact-me-title {
    scroll-margin-top: 64px;
    font-family: Helvetica;
    font-size: 30pt;
    text-align: center;
}

#contact-form {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 5px 5px 5px 5px #c2c2c2;
    border-radius: 10px;
}

#contact-form * {
    margin: 5px 0;
}

#alert {
    text-align: center;
}

.github-logo {
    width: 150px;
    background-color: black;
    padding: 10px;
    border-radius: 15px;
    display: block;
    margin: 0 auto;
}

.linkedin-logo {
    font-size: 100px;
    color: #0072b1;
    display: block;
    margin: 0 auto;
}

.inline-text-h3 {
    display: inline-block;
}

.inline-text-p {
    display: inline-block;
    font-size: 14pt;
}

.logos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.logos a {
    flex: 1;
}

.helper-text {
    text-align: center;
    margin: 15px 0;
}

.final-text {
    margin-top: 70px;
    margin-bottom: 20px;
    text-align: center;
}