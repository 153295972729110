* {
    padding: 0;
    margin: 0;
}

.hero-section {
    height: 100vh;
    background-image: linear-gradient(to top, rgba(194, 203, 249, 0.52), #2193b0b1);    
    width: 100vw;
    background-repeat: no-repeat;
    background-color: #a1cfda;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-space {
    height: 64px;
}

.hero-text-box {
    text-align: center;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size: xx-large;
    border: 3px solid white;
    padding: 45px;
}

.hero-name-extra {
    color: white;
}

.hero-name {
    /* background: linear-gradient(to right,#1f1fb1, #1f0b59); */
    color: white;
    display: inline;
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
}


@media (max-width: 870px) {
    .hero-name {
        font-size: 30pt;
    }

    .hero-name-extra {
        font-size: 14pt;
    }
    .hero-text-box {
        padding: 10px;
    }
}