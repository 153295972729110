.projects-section {
    margin-top: 10px;
    overflow-x: hidden;
}

#projects-section {
    margin-top: 64px;
    font-family: Helvetica;
    font-size: 30pt;
    text-align: center;
}

@media (max-width: 870px) {
    #projects-section {
        scroll-margin-top: 44px;
    }
}